.progressContainer {
  background-color: rgb(233, 233, 233);
  border-radius: 0.5rem;
  margin-bottom: 25px;
}

.progress {
  background-color: #3a44a2;
  height: 10px;
  border-radius: 3px;
  transition: 1s ease;
  transition-delay: 0.5s;
}
