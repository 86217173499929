@font-face {
  font-family: 'Lato';
  src: local('Lato') url('./assets/fonts/Lato-Regular.ttf');
}
* {
  font-family: 'Lato', sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
  background-image: radial-gradient(circle at 48% 35%, #3a44a2, rgba(0, 0, 0, 0.5)),
    linear-gradient(to bottom, #000, #000) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and(max-width:768px) {
  .ant-modal-wrap {
    padding: 0 10px !important;
  }
}
